import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Menu from './components/Menu/Menu';
import ProfileScreen from './screens/ProfileScreen/ProfileScreen';
import RequestsScreen from './screens/RequestsScreen/RequestsScreen';
import ExchangeScreen from './screens/ExchangeScreen/ExchangeScreen';
import ExchangeInfoScreen from './screens/ExchangeScreen/ExchangeInfoScreen';
import AmlScreen from './screens/AmlScreen/AmlScreen';
import AmlInfoScreen from './screens/AmlScreen/AmlInfoScreen';
import Chat from './components/Chat/Chat';
import ServicesScreen from './screens/ServicesScreen/ServicesScreen';
import VedScreen from './screens/VedScreen/VedScreen';
import VedAboutScreen from './screens/VedAboutScreen/VedAboutScreen';
import VedInfoScreen from './screens/VedInfoScreen/VedInfoScreen';
import InvoiceScreen from './screens/Invoice/InvoiceScreen/InvoiceScreen';
import InvoiceInfoScreen from './screens/Invoice/InvoiceInfoScreen/InvoiceInfoScreen';
import InvoiceFeeInfoScreen from './screens/Invoice/InvoiceFeeInfoScreen/InvoiceFeeInfoScreen';
import InvoiceCashScreen from './screens/Invoice/InvoiceCashScreen/InvoiceCashScreen';
import InvoiceUsdtScreen from './screens/Invoice/InvoiceUsdtScreen/InvoiceUsdtScreen';
import webAppStore from './stores/WebAppStore';
import ReferralScreen from './screens/ReferralScreen/ReferralScreen';

const AppContent = observer(() => {
  const location = useLocation();

  useEffect(() => {
    const pathsWithoutMenu = [
      '/change',
      '/changeInfo',
      '/invoicePayment',
      '/invoiceInfo',
      '/invoiceFeeInfo',
      '/invoiceCash',
      '/invoiceUsdt',
      '/ved',
      '/vedAbout',
      '/vedInfo',
      '/aml',
      '/amlInfo',
    ];
    const isMenuVisible = !pathsWithoutMenu.includes(location.pathname);
    webAppStore.setMenuVisibility(isMenuVisible);
  }, [location]);

  useEffect(() => {
    // Track initial visibility based on current path
    webAppStore.updateBackButtonVisibility();
    // Listen for history changes
    const handlePopState = () => {
      webAppStore.updateBackButtonVisibility();
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return (
    <div className="App">
      <div className={`content ${!webAppStore.isMenuVisible ? 'full' : ''}`}>
        <Routes>
          <Route path="/" element={<ServicesScreen />} />
          <Route path="/profile" element={<ProfileScreen />} />
          <Route path="/requests" element={<RequestsScreen />} />
          <Route path="/change" element={<ExchangeScreen />} />
          <Route path="/changeInfo" element={<ExchangeInfoScreen />} />
          <Route path="/aml" element={<AmlScreen />} />
          <Route path="/amlInfo" element={<AmlInfoScreen />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/ved" element={<VedScreen />} />
          <Route path="/vedAbout" element={<VedAboutScreen />} />
          <Route path="/vedInfo" element={<VedInfoScreen />} />
          <Route path="/invoicePayment" element={<InvoiceScreen />} />
          <Route path="/invoiceInfo" element={<InvoiceInfoScreen />} />
          <Route path="/invoiceFeeInfo" element={<InvoiceFeeInfoScreen />} />
          <Route path="/invoiceCash" element={<InvoiceCashScreen />} />
          <Route path="/invoiceUsdt" element={<InvoiceUsdtScreen />} />
          <Route path="/refs" element={<ReferralScreen />} />
        </Routes>
      </div>
      {webAppStore.isMenuVisible && <Menu />}
    </div>
  );
});
const App = () => (
  <Router>
    <AppContent /> {/* Теперь AppContent обернут в <Router> */}
  </Router>
);
export default App;